



























































































































@import '~@/assets/styles/helpers/_variables.scss';
.subCategory {
  font-size: 14px;
  color: #888888;
}
